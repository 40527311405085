import "rc-steps/assets/index.css";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import Login from "../components/steps/Login";
import { useAuthContext } from "../context/AuthContext";
import { API } from "../utils/constant";

const onSubmit = async (values) => {
  window.alert(JSON.stringify(values, 0, 2));
};

function LoginPage() {
  const { setJwt, setUser } = useAuthContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.id,
        password: values.inlogcode,
      };

      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();

      if (data.error) {
        setError(
          "De ingevoerde gegevens lijken niet correct. Probeer het opnieuw of neem contact met ons op."
        );
        throw Error;
      }

      if (data.user.beleggersprofiel) {
        setError(
          "Je hebt reeds je beleggersprofiel doorgegeven. Neem contact met ons op."
        );
        throw Error;
      }

      setJwt(data.jwt)
      setUser(data.user);
      navigate("/personal-information");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-10 pb-24">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full lg:mr-2 text-accent py-6">
          <h1 className="header-primary">
            Het Belang van een goed beleggersprofiel
          </h1>
          <p>
            Wanneer u begint met beleggen, is het belangrijk dat uw beleggingen nauw aansluiten bij uw persoonlijke doelen, risicobereidheid en financiële situatie. Dit is waar een goed beleggersprofiel een cruciale rol speelt. Een zorgvuldig opgesteld beleggersprofiel vormt namelijk de basis van een succesvolle beleggingsstrategie.
          </p>
          <h1 className="header-secondary mt-6">
            Wat is een beleggersprofiel?
          </h1>
          <p>
            Een beleggersprofiel geeft een helder beeld van uw financiële doelstellingen, hoeveel risico u bereid bent te nemen, en op welke termijn u resultaten verwacht. Het helpt u en uw adviseur om de juiste beleggingskeuzes te maken, passend bij uw unieke situatie.
          </p>
          <h1 className="header-secondary  mt-6">
            Waarom is een beleggersprofiel zo belangrijk?
          </h1>
          <p>
            <ol>
              <li><b>1.	Afgestemd op uw doelen</b>: Uw beleggersprofiel zorgt ervoor dat uw beleggingsstrategie afgestemd is op wat u echt wilt bereiken, zoals bijvoorbeeld sparen voor uw pensioen, de studie van uw kinderen, of het opbouwen van een noodfonds.</li>
              <li><b>2.	Balans tussen risico en rendement</b>: Beleggen gaat altijd gepaard met een bepaald risico. Een beleggersprofiel helpt om de balans te vinden tussen de potentiële rendementen die u nastreeft en het risico dat u bereid bent te nemen.</li>
              <li><b>3.	Inzicht en controle</b>: Een helder profiel biedt u inzicht in uw financiële situatie en helpt u controle te houden over uw beleggingen. Zo voorkomt u dat u onnodige risico’s loopt of investeert in producten die niet passen bij uw doelen.</li>
              <li><b>4.	Dynamisch en flexibel</b>: Uw persoonlijke omstandigheden kunnen veranderen. Daarom is het belangrijk om uw beleggersprofiel regelmatig te herzien, zodat het blijft aansluiten op uw actuele situatie en de veranderende marktomstandigheden.</li>
            </ol>
          </p>
          <h1 className="header-secondary mt-6">
            Hoe werkt het?
          </h1>
          <p>
            Bij het opstellen van een beleggersprofiel nemen we een aantal belangrijke factoren mee:
            <ul>
              <li>•	Uw <b>financiële doelstellingen</b> (kort- en langetermijn)</li>
              <li>•	Uw <b>risicobereidheid</b></li>
              <li>•	De <b>looptijd</b> van uw beleggingen</li>
              <li>•	Uw <b>kennis en ervaring</b> op het gebied van beleggen</li>
            </ul>
            Op basis van deze informatie stellen we een persoonlijk profiel op dat de leidraad vormt voor uw beleggingsstrategie.
          </p>
          <h1 className="header-secondary mt-6">
            Houd uw profiel actueel
          </h1>
          <p>
            Uw beleggersprofiel is geen statisch gegeven. Marktontwikkelingen, veranderingen in uw persoonlijke leven, of nieuwe financiële doelen kunnen ervoor zorgen dat uw profiel bijgesteld moet worden. Daarom is het verstandig om periodiek uw profiel te herzien, zodat uw beleggingen altijd optimaal blijven presteren.
            <br />Wilt u zeker weten dat uw beleggingen nog steeds aansluiten bij uw wensen en doelen? Breng dan hieronder de twee unieke codes in die u in u via mail ontving en vul de online vragenlijst in.
          </p>
        </div>
      </div>

      <Form
        onSubmit={onFinish}
        validate={(values) => {
          const errors = {};
          if (!values.id) {
            errors.id = "Required";
          }
          if (!values.inlogcode) {
            errors.inlogcode = "Required";
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} 
            className="mt-10">
            <Login error={error} />

            <div className="flex justify-end">

              <div className="button-primary tracking-wider" onClick={handleSubmit}>Verder</div>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default LoginPage;
