export const questions = [
  {
    id: 1,
    question: "Wat is uw belangrijkste bron van inkomen?",
    answers: [
      {
        id: "1",
        text: "Inkomen uit pensioen, vervangingsinkomen,…",
        points: 5,
      },
      { id: "2", text: "Inkomen uit loondienst", points: 5 },
      {
        id: "3",
        text: "Inkomen uit zelfstandig ondernemen",
        correct: true,
        points: 10,
      },
      { id: "4", text: "Inkomen uit vermogen", points: 15 },
    ],
  },
  {
    id: 2,
    question: "Bent u eigenaar van één of meerdere onroerende goederen?",
    answers: [
      { id: "1", text: "Neen, daar heb ik ook geen interesse in", points: 5 },
      {
        id: "2",
        text: "Neen, maar ik ben van plan om dit in de toekomst aan te kopen",
        points: 10,
      },
      {
        id: "3",
        text: "Ja, ik ben eigenaar van vastgoed voor eigen gebruik",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Ja, ik ben eigenaar van meerdere onroerende goederen",
        points: 20,
      },
    ],
  },
  {
    id: 3,
    question: "Hoeveel bedraagt uw roerend vermogen?",
    answers: [
      { id: "1", text: "Minder dan € 50.000", points: 5 },
      { id: "2", text: "Tussen € 50.000 en € 250.000", points: 10 },
      {
        id: "3",
        text: "Tussen € 250.000 en € 1.000.000",
        correct: true,
        points: 15,
      },
      { id: "4", text: "Meer dan € 1.000.000", points: 20 },
    ],
  },
  {
    id: 4,
    question:
      "Welk deel van uw gezinsinkomen wordt momenteel besteed aan de afbetaling van kredieten?",
    answers: [
      { id: "1", text: "Meer dan 35%", points: 5 },
      { id: "2", text: "Van 20% tot en met 35%", points: 10 },
      {
        id: "3",
        text: "Minder dan 20%",
        correct: true,
        points: 15,
      },
    ],
  },
  {
    id: 5,
    question: "Hoeveel kunt u maandelijks sparen?",
    answers: [
      { id: "1", text: "Minder dan 250 EUR", points: 5 },
      { id: "2", text: "Tussen 250 EUR en 500 EUR", points: 10 },
      {
        id: "3",
        text: "Tussen 500 EUR en 1.000 EUR",
        correct: true,
        points: 15,
      },
      { id: "4", text: "Meer dan 1.000 EUR", points: 20 },
    ],
  },
  {
    id: 6,
    question: "Uw kennis inzake beleggingen is?",
    answers: [
      { id: "1", text: "Eerder bescheiden", points: 5 },
      { id: "2", text: "Gemiddeld", points: 10 },
      {
        id: "3",
        text: "Erg goed",
        correct: true,
        points: 15,
      },
    ],
  },
  {
    id: 7,
    question:
      "Welke uitspraak is het meest op u van toepassing inzake uw huidige ervaring met beleggen?",
    answers: [
      {
        id: "1",
        text: "Dit zijn mijn eerste stappen in de wereld van beleggen",
        points: 5,
      },
      {
        id: "2",
        text: "Ik beheer sinds enkele jaren een portefeuille van minder dan € 75.000",
        points: 10,
      },
      {
        id: "3",
        text: "Ik beheer al vele jaren een portefeuille tussen € 75.000 en € 500.000",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Ik beheer al meerdere jaren een portefeuille van meer dan € 500.000",
        points: 20,
      },
    ],
  },
  {
    id: 8,
    options: true,
    question:
      "In welke van de volgende beleggingen heeft u de afgelopen vijf jaar reeds belegd?",
    answers: [
      {
        id: "1",
        text: "Individuele obligaties",
        points: 5,
      },
      {
        id: "2",
        text: "Beleggingsverzekering van het type Tak 21",
        points: 5,
      },
      {
        id: "3",
        text: "Bancair beleggingsfonds met kapitaalbescherming",
        points: 5,
      },
      {
        id: "4",
        text: "Bancair beleggingsfonds zonder kapitaalbescherming",
        points: 5,
      },
      {
        id: "5",
        text: "Beleggingsverzekering van het type Tak 23",
        points: 5,
      },
      {
        id: "6",
        text: "Individuele aandelen",
        points: 5,
      },
      {
        id: "7",
        text: "Grondstoffen of edelmetalen",
        points: 5,
      },
      {
        id: "8",
        text: "Opties, futures of andere complexe beleggingsproducten",
        points: 5,
      },
    ],
  },
  {
    id: 9,
    question:
      "Wat is uw reactie wanneer de waarde van uw beleggingsportefeuille in korte tijd aanzienlijk daalt?",
    answers: [
      {
        id: "1",
        text: "Ik verkoop mijn belegging om onnodige zorgen te vermijden",
        points: 5,
      },
      {
        id: "2",
        text: "Ik onderneem niets en wacht af",
        points: 10,
      },
      {
        id: "3",
        text: "Ik koop bij en versterk mijn posities. Het is een goede belegging en ik kan nu tegen een lagere prijs aankopen.",
        correct: true,
        points: 15,
      },
    ],
  },
  {
    id: 10,
    question:
      "Hoelang kan u het geld dat u belegd heeft of zou willen beleggen missen?",
    answers: [
      {
        id: "1",
        text: "Minder of gelijk aan 3 jaar",
        points: 5,
      },
      {
        id: "2",
        text: "3 tot 5 jaar",
        points: 10,
      },
      {
        id: "3",
        text: "5 tot 8 jaar",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Langer dan 8 jaar",
        points: 20,
      },
    ],
  },
  {
    id: 11,
    question: "Wat is de belangrijkste doelstelling met uw beleggingen?",
    answers: [
      {
        id: "1",
        text: "Een noodzakelijke aanvulling op het huidige inkomen",
        points: 5,
      },
      {
        id: "2",
        text: "De aflossing van mijn hypotheek of andere schulden",
        points: 10,
      },
      {
        id: "3",
        text: "Een specifieke, noodzakelijke uitgave in de toekomst",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Een specifieke, niet noodzakelijke uitgave in de toekomst",
        points: 20,
      },
      {
        id: "5",
        text: "(Algemene) vermogensgroei",
        points: 25,
      },
    ],
  },
  {
    id: 12,
    question: "Hoeveel tijd hebt u voor het bereiken van uw doel?",
    answers: [
      {
        id: "1",
        text: "1 tot 2 jaar",
        points: 5,
      },
      {
        id: "2",
        text: "3 tot 5 jaar",
        points: 10,
      },
      {
        id: "3",
        text: "6 tot 10 jaar",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "11 tot 20 jaar",
        points: 20,
      },
      {
        id: "5",
        text: "Meer dan 20 jaar",
        points: 25,
      },
    ],
  },
  {
    id: 13,
    question: "In welke leeftijdscategorie valt u?",
    answers: [
      {
        id: "1",
        text: "Ouder dan 75 jaar",
        points: 5,
      },
      {
        id: "2",
        text: "Tussen 61 en 75 jaar",
        points: 10,
      },
      {
        id: "3",
        text: "Tussen 46 en 60 jaar",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Tussen 30 en 45 jaar",
        points: 20,
      },
      {
        id: "5",
        text: "Jonger dan 30",
        points: 25,
      },
    ],
  },
  {
    id: 14,
    question: "Hoeveel gemiddeld rendement verwacht u per jaar te realiseren?",
    answers: [
      {
        id: "1",
        text: "Een rendement minimaal gelijk aan de spaarrente",
        points: 5,
      },
      {
        id: "2",
        text: "Ongeveer 3%",
        points: 10,
      },
      {
        id: "3",
        text: "Ongeveer 5%",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "Minstens 7%",
        points: 20,
      },
    ],
  },
  {
    id: 15,
    question:
      "Beleggingen kunnen sterk fluctueren. Welke waardedaling in een slecht beleggingsjaar vindt u acceptabel?",
    answers: [
      {
        id: "1",
        text: "0 tot 5%",
        points: 5,
      },
      {
        id: "2",
        text: "5 tot 10%",
        points: 10,
      },
      {
        id: "3",
        text: "10 tot 15%",
        correct: true,
        points: 15,
      },
      {
        id: "4",
        text: "15 tot 25%",
        points: 20,
      },
      {
        id: "5",
        text: "Meer dan 25%",
        points: 25,
      },
    ],
  },
];

export const profielen = [
  {
    profiel: "Voorzichtig",
    min: null,
    max: 120,
  },
  {
    profiel: "Defensief",
    min: 121,
    max: 170,
  },
  {
    profiel: "Evenwichtig",
    min: 171,
    max: 210,
  },
  {
    profiel: "Dynamisch",
    min: 211,
    max: null,
  },
];

const duurzaamheidsVragen = [
  {
    id: 1,
    question: "Heeft u duurzaamheidsvoorkeuren voor uw portefeuille?",
    answers: [
      {
        id: "1",
        text: "Ja",
      },
      {
        id: "2",
        text: "Nee ",
      },
    ],
  },
];
