export const duurzaamheidsQuestions = [
  {
    id: 2,
    question:
      "Hoe belangrijk is het voor u dat beleggingen rekening houden met negatieve impact op het milieu of op sociale aspecten? (koolstofuitstoot, broeikasgassen, activiteiten die een belangrijke ongunstige impact hebben op klimaat, waterverbruik, gevaarlijk afval, schending mensenrechten en andere werknemersaangelegenheden)",
    answers: [
      {
        id: "1",
        text: "Niet belangrijk",
        points: 0,
      },
      { id: "2", text: "Belangrijk", points: 10 },
    ],
  },
  {
    id: 3,
    question: "In welke mate wilt u dat beleggingen exclusief focussen op een positieve bijdrage aan het milieu en de maatschappij?",
    answers: [
      {
        id: "1",
        text: "Niet belangrijk",
        points: 0,
      },
      { id: "2", text: "Van belang", points: 15 },
      { id: "3", text: "Zeer belangrijk", points: 30 },
    ],
  },
];

export const duurzaamheidsProfielen = [
  {
    profiel: "Geen voorkeur",
    min: 0,
    max: 0,
    samenstellingPortefeuille: "geen specifieke vereisten",
  },
  {
    profiel: "Observerend",
    min: 10,
    max: 10,
    samenstellingPortefeuille: "min 50% Artikel 8",
  },
  {
    profiel: "Bewust",
    min: 15,
    max: 25,
    samenstellingPortefeuille: "minimum 75% Artikel 9 + Artikel 8",
  },
  {
    profiel: "Ambitieus",
    min: 30,
    max: 40,
    samenstellingPortefeuille: "minimum 50% Artikel 9 + max 10% Artikel 6",
  },
];
