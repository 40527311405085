import React, { useContext, useState } from "react";
import { Field, Form, useForm } from "react-final-form";
import { useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import "./App.css";
import Condition from "./components/form/Condition";
import { AuthContext } from "./context/AuthContext";
import {
  duurzaamheidsProfielen, duurzaamheidsQuestions
} from "./data/duurzaamheidsvoorkeuren";
import { profielen, questions } from "./data/questions";
import { API } from "./utils/constant";

const calculateBeleggersProfiel = (values) => {
  let score = 0;
  const points = questions.map((question, i) => {
    const answers = question?.answers.find(
      (answer) => answer?.id === values[i]?.answerId
    )?.points;
    if (!answers) {
      const smth = values[i]?.answerId.map(answerId => question.answers.find((answer) => answerId === answer.id)?.points)
      const smthPoints = smth.reduce((partialSum, a) => partialSum + a, 0)
      console.log(smth.reduce((partialSum, a) => partialSum + a, 0))
      score = score + smthPoints

    } else {
      score = score + answers;

    }
  });

  const beleggersprofiel = profielen.find((profiel) => {
    return (
      (profiel?.min === null || profiel?.min <= score) &&
      (profiel?.max === null || profiel?.max >= score)
    );
  });
  return {
    'profiel': beleggersprofiel.profiel,
    'score': score
  }
};

const calculateDuurzaamheidProfiel = (values) => {
  let score = 0;
  const points = duurzaamheidsQuestions.map((question, i) => {
    const answers = question?.answers.find(
      (answer) => answer?.id === values[i]?.answerId
    );
    score = score + answers?.points ? answers?.points : 0;
  });

  const beleggersprofiel = duurzaamheidsProfielen.find((profiel) => {
    return (
      (profiel?.min === null || profiel?.min <= score) &&
      (profiel?.max === null || profiel?.max >= score)
    );
  });
  return {
    'profiel': beleggersprofiel.profiel,
    'score': score
  }
};

const required = (value) =>
  value ? undefined : (
    <span className="text-omegaRed">
      * <span className="text-sm">verplicht</span>
    </span>
  );

function DuurzaamheidVoorkeuren() {
  const [isLoading, setIsLoading] = useState(false);


  const { user, formValues, jwt } = useContext(AuthContext);

  const post = async (values) => {

    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
        },
        body: JSON.stringify({
          beleggersprofiel: {
            questions: values.questions,
            score: calculateBeleggersProfiel(values.questions).score,
            profiel: calculateBeleggersProfiel(values.questions).profiel,
          },
          naamPersoon1: values.person1.name,
          geboortedatumPersoon1: values.person1.birthDate,
          naamPersoon2: values.person2?.name ?? "",
          geboortedatumPersoon2: values.person2?.birthDate ?? "",
          duurzaamheidsProfiel: {
            questions: values.hasDuurzaamheidsvoorkeuren === "true" ? values.duurzaamheidsQuestions : [],
            profiel: calculateDuurzaamheidProfiel(
              values.hasDuurzaamheidsvoorkeuren === "true" ? values.duurzaamheidsQuestions : []
            ).profiel,
            score: calculateDuurzaamheidProfiel(
              values.hasDuurzaamheidsvoorkeuren === "true" ? values.duurzaamheidsQuestions : []
            ).score,
          },
          adres: values.adres,
          handtekeningDuurzaamheidsProfiel: values.handtekeningDuurzaamheidsProfiel,
          handtekeningBeleggersProfiel: values.handtekeningBeleggersProfiel,
          stuurPdf: true,
          stuurInviteerMailConfirmed: true,
        }),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate("/confirmation");
    }
  };

  const navigate = useNavigate();

  const initialValue = {
    questions: duurzaamheidsQuestions.map((question, i) => ({
      questionId: question.id,
      answerId: null,
    })),
  };

  return (
    <div>
      <div className="App px-10">
        <header className="App-header">

          <div className="mt-14 w-full">
            <h1 className="header-primary bg-primary text-white flex justify-center py-4 ">
              duurzaamheidsvoorkeuren
            </h1>
            <p>
              Overeenkomstig de nieuwste Mifid II regels moeten we bij iedere
              advies inzake beleggingen peilen naar de mate waarin onze klanten
              wensen dat er rekening gehouden wordt met duurzaamheidsvoorkeuren.
            </p>
            <p>
              Artikel 6 producten: beleggingsproducten waarin geen
              duurzaamheidsfactoren zijn geïntegreerd
            </p>
            <p>
              Artikel 8 producten: beleggingsproducten die niet uitsluitend
              duurzaam beleggen beogen. Deze producten bevorderen echter milieu-
              en of sociale kenmerken in een deel of het geheel van hun activa
            </p>
            <p>
              Artikel 9 producten: beleggingsproducten met als exclusief doel
              duurzame beleggingen
            </p>

            <Form
              onSubmit={(values) => {
                post(values);
              }}
              initialValues={{ ...initialValue, ...formValues }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="py-8">
                    <Field
                      name="hasDuurzaamheidsvoorkeuren"
                      validate={required}
                    >
                      {({ meta }) => (
                        <div className="customico">
                          <li className="question-title">
                            1. duurzaamheidsvoorkeuren
                            {meta.error && meta.touched && meta.error}
                          </li>
                        </div>
                      )}
                    </Field>
                    <p className="question-answers flex items-center">
                      <Field
                        name="hasDuurzaamheidsvoorkeuren"
                        type="radio"
                        value="true"
                        component="input"
                        className="mr-1"
                      />
                      Ja
                    </p>
                    <p className="question-answers flex items-center">
                      <Field
                        name="hasDuurzaamheidsvoorkeuren"
                        type="radio"
                        value="false"
                        component="input"
                        className="mr-1"
                      />
                      Nee (einde vragenlijst)
                    </p>
                  </div>
                  <Condition when="hasDuurzaamheidsvoorkeuren" is="true">
                    {duurzaamheidsQuestions.map((question, i) => {
                      return (
                        <div className="py-8" key={i}>
                          <Field
                            name={`duurzaamheidsQuestions[${i}].answerId`}
                            validate={required}
                          >
                            {({ meta }) => (
                              <div className="customico">
                                <li className="question-title">
                                  {question.id}. {question.question}{" "}
                                  {meta.error && meta.touched && meta.error}
                                </li>
                              </div>
                            )}
                          </Field>

                          {question.answers.map((answer) => {
                            return (
                              <p
                                key={answer.id}
                                className="question-answers flex items-center"
                              >
                                <Field
                                  name={`duurzaamheidsQuestions[${i}].answerId`}
                                  type="radio"
                                  value={answer.id}
                                  component="input"
                                  points={answer.points}
                                  className="mr-1"
                                />
                                {answer.text}
                              </p>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Condition>
                  <SignatureField />
                  <div className="flex justify-end">

                    <button
                      className="button-primary my-6"
                      onClick={handleSubmit}
                    >
                      Volgende
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </header>
      </div>
    </div>
  );
}

const SignatureField = () => {
  let signature;
  const form = useForm();

  return (
    <div className="w-full bg-primary flex justify-center p-10">
      <div className="flex flex-col items-center">
        <Field name={`handtekeningDuurzaamheidsProfiel`} validate={required}>
          {({ meta }) => (
            <div>
              <h2 className="uppercase text-xl text-white font-semibold mb-6">
                Handtekening{" "}
                {meta.error && meta.touched && (
                  <span className="text-omegaRed">{meta.error}</span>
                )}
              </h2>
            </div>
          )}
        </Field>

        <div>
          <ReactSignatureCanvas
            canvasProps={{
              width: 500,
              height: 200,
              className: " rounded-lg bg-white",
            }}
            ref={(ref) => {
              signature = ref;
            }}
            onEnd={() => {
              form.mutators.setValue(
                "handtekeningDuurzaamheidsProfiel",
                signature.getTrimmedCanvas().toDataURL("image/png")
              );
            }}
          />
          <div
            className="text-sm cursor-pointer text-right text-white mt-2"
            onClick={() => signature.clear()}
          >
            wissen
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuurzaamheidVoorkeuren;
