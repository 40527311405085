import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Condition from "./components/form/Condition";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { API } from "./utils/constant";

const required = (value) =>
  value ? undefined : (
    <span className="text-omegaRed">
      * <span className="text-sm">verplicht</span>
    </span>
  );

const registerNewContact = async (user, jwt, data) => {
  // user var is ready to implement advisor

  const pass = Math.random().toString(36).slice(-8);

  const postdata = {
    "username": data.name,
    "email": data.email,
    "password": pass,
    "inlogcode": pass,
    "role": "authenticated",
    "advisor": user.advisor,
    "advisorEmail": user.advisorEmail,
    "beheerder": user.beheerder,
    "beheerderEmail": user.beheerderEmail
  };

  try {
    const response = await fetch(`${API}/auth/local/register/`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(postdata),

    });
    const data = await response.json();

    if (!response.ok) {
      throw Error;
    }

  } catch (error) {
    alert(
      "Er is iets misgegaan bij het automatisch uitnodigen van je partner. Contacteer ons aub."
    )
  }
};

function PersonalInformation() {
  const navigate = useNavigate();

  const { user, jwt, setFormValues, formValues } = useContext(AuthContext);

  return (
    <div className="App px-10">
      <header className="App-header">
        <div className="mt-14 w-full">
          <h1 className="header-primary bg-primary text-white flex justify-center py-4 ">
            Persoonlijke informatie
          </h1>

          <Form
            onSubmit={(values) => {
              // Exclude contact information if "no" is selected
              const formData = { ...values };
              if (formData.addPerson2 === "no") {
                registerNewContact(user, jwt, formData.contact);
                delete formData.contact;
              }
              setFormValues({ ...formValues, ...formData });
              navigate("/beleggersprofiel");
            }}
            initialValues={{}}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="md:px-1 header-primary mt-4">GEGEVENS</div>
                <div className="flex flex-col md:flex-row">
                  <div className="flex flex-col w-full md:w-1/2 md:px-1">
                    <Field name="person1.name" validate={required}>
                      {({ input, meta }) => (
                        <div className="py-1 pb-2">
                          <div className="mb-2">Naam</div>
                          <input
                            {...input}
                            className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                            type="text"
                            required
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 md:px-1">
                    <Field name="person1.birthDate" validate={required}>
                      {({ input, meta }) => (
                        <div className="py-1 pb-2">
                          <div className="mb-2">Geboortedatum</div>
                          <input
                            {...input}
                            className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                            type="text"
                            required
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex flex-col w-full md:px-1 mt-2">
                  <Field name="adres" validate={required}>
                    {({ input, meta }) => (
                      <div className="py-1 pb-2">
                        <div className="mb-2">Adres</div>
                        <input
                          {...input}
                          className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                          type="text"
                          required
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="flex flex-col w-full md:px-1 mt-4">
                  <div className="py-1 pb-2">
                    <div className="mb-2">Is dit beleggersprofiel ook van toepassing voor uw partner?</div>
                    <label>
                      <Field name="addPerson2" component="input" type="radio" value="yes" />
                      {"  "}Ja
                    </label>
                    <br />
                    <label className="">
                      <Field name="addPerson2" component="input" type="radio" value="no" />
                      {"  "}Nee, bezorg mijn partner een individuele vragenlijst
                    </label>
                  </div>
                </div>

                <Condition when="addPerson2" is="yes">
                  <div className="my-4 mt-10">
                    <div className="flex justify-between items-center">
                      <div className="md:px-1 header-primary">GEGEVENS PARTNER</div>
                        <div
                          className="text-sm cursor-pointer text-right text-primary"
                          onClick={() =>
                            form.mutators.setValue("addPerson2", undefined)
                          }
                        >
                          annuleren
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                      <div className="flex flex-col w-full md:w-1/2 md:px-1">
                        <Field name="person2.name" validate={required}>
                          {({ input, meta }) => (
                            <div className="py-1 pb-2">
                              <div className="mb-2">Naam</div>
                              <input
                                {...input}
                                className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                                type="text"
                                required
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="flex flex-col w-full md:w-1/2 md:px-1">
                        <Field name="person2.birthDate" validate={required}>
                          {({ input, meta }) => (
                            <div className="py-1 pb-2">
                              <div className="mb-2">Geboortedatum</div>
                              <input
                                {...input}
                                className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                                type="text"
                                required
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </Condition>

                <Condition when="addPerson2" is="no">
                  <div className="my-4 mt-10">
                  <div className="flex justify-between items-center">
                      <div className="md:px-1 header-primary">Contactgegevens partner</div>
                        <div
                          className="text-sm cursor-pointer text-right text-primary"
                          onClick={() =>
                            form.mutators.setValue("addPerson2", undefined)
                          }
                        >
                          annuleren
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:px-1">
                      <Field name="contact.name">
                        {({ input, meta }) => (
                          <div className="py-1 pb-2">
                            <div className="mb-2">Naam</div>
                            <input
                              {...input}
                              className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                              type="text"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex flex-col w-full md:px-1 mt-2">
                      <Field name="contact.email">
                        {({ input, meta }) => (
                          <div className="py-1 pb-2">
                            <div className="mb-2">E-mail</div>
                            <input
                              {...input}
                              className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                              type="email"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </Condition>
                
                <div className="mt-4">
                  <p>
                    Indien u over een vennootschap(pen) beschikt, zal dit profiel ook van toepassing zijn op deze vennootschap(pen).{" "}
                  </p>
                </div>

                <div className="flex justify-end mt-4">
                  <button
                    className="button-primary"
                    onClick={handleSubmit}
                  >
                    Volgende
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </header>
    </div>
  );
}

export default PersonalInformation;
