import React from "react";
import { BiCheck } from "react-icons/bi";
import "./App.css";

function Confirmation() {
  return (
    <div className="w-full bg-primary flex flex-col text-center items-center justify-center p-10">
      <div className="flex flex-row items-center">
        <div className="text-4xl uppercase font-medium  text-white flex">
          GOED GELUKT
        </div>
        <BiCheck className="text-white text-9xl" />
      </div>
      <div className="text-white">
        <p>We hebben je gegevens succesvol geregistreerd. Je ontvangt binnenkort een e-mail van ons.</p>

      </div>
    </div>
  );
}

export default Confirmation;
