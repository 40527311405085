import { createContext, useContext } from "react";

export const AuthContext = createContext({
  user: undefined,
  isLoading: false,
  formValues: undefined,
  jwt: undefined,
  setFormValues: () => {},
  setUser: () => {},
  setJwt: () => {},
});

export const useAuthContext = () => useContext(AuthContext);
