import React from "react";
import { Field } from "react-final-form";

function Login({ error }) {
  return (
    <div>
      <div>
        <h1 className="header-primary">Login</h1>
        <p>
          Vul hieronder jouw identificatie-code en inlogcode in, deze staan in
          de mail die je ontvangen hebt.
        </p>
      </div>
      <div className="text-accent mt-2">
        <Field name="id">
          {({ input }) => (
            <div className="py-1 pb-2">
              <div className="mb-2">Identificatie-code</div>
              <input
                {...input}
                className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                type="text"
                required
              />
            </div>
          )}
        </Field>
        <Field name="inlogcode" className="my-4">
          {({ input }) => (
            <div className="py-1">
              <div className="mb-2">Logincode</div>
              <input
                {...input}
                className="bg-omegaGrey w-full text-base py-2 px-1 rounded"
                type="password"
                required
              />
              {
                <div className="text-omegaRed text-sm mt-2 font-medium">
                  {error}
                </div>
              }
            </div>
          )}
        </Field>
      </div>
    </div>
  );
}

export default Login;
