import { useContext } from "react";
import { Field, Form, useForm } from "react-final-form";
import { useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import "./App.css";
import { AuthContext } from "./context/AuthContext";
import { questions } from "./data/questions";

const required = (value) =>
  value ? undefined : (
    <span className="text-omegaRed">
      * <span className="text-sm">verplicht</span>
    </span>
  );

function BeleggersProfiel() {
  const navigate = useNavigate();
  const { setFormValues, formValues } = useContext(AuthContext);


  const initialValue = {
    questions: questions.map((question, i) => ({
      questionId: question.id,
      answerId: null,
    })),
  };
  return (
    <div className="App">
      <header className="App-header">

        <div className="mt-14 w-full">
          <h1 className="header-primary bg-primary text-white flex justify-center py-4 ">
            Beleggersprofiel
          </h1>

          <Form
            onSubmit={(values) => {
              setFormValues({ ...formValues, ...values });

              navigate("/duurzaamheidvoorkeuren");
            }}
            initialValues={initialValue}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {questions.map((question, i) => {
                  return (
                    <div className="py-8" key={i}>
                      <Field
                        name={`questions[${i}].answerId`}
                        validate={required}
                      >
                        {({ meta }) => (
                          <div className="customico">
                            <li className="question-title">
                              {i + 1}. {question.question}{" "}
                              {meta.error && meta.touched && meta.error}
                            </li>
                          </div>
                        )}
                      </Field>

                      {question.answers.map((answer) => {
                        if (question.id === 8) {
                          return (
                            <p
                              key={answer.id}
                              className="question-answers flex items-center"
                            >
                              <Field
                                name={`questions[${i}].answerId`}
                                type="checkbox"
                                value={answer.id}
                                component="input"
                                points={answer.points}
                                className="mr-1"
                              />
                              {answer.text}
                            </p>
                          );
                        }
                        return (
                          <p
                            key={answer.id}
                            className="question-answers flex items-center"
                          >
                            <Field
                              name={`questions[${i}].answerId`}
                              type="radio"
                              value={answer.id}
                              component="input"
                              points={answer.points}
                              className="mr-1"
                            />
                            {answer.text}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
                <SignatureField />
                <div className="flex justify-between">
                  <button
                    className="button-primary my-6"
                    onClick={() => navigate("/personal-information")}
                  >
                    Vorige
                  </button>
                  <button
                    className="button-primary my-6"
                    onClick={handleSubmit}
                  >
                    Volgende
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </header>
    </div>
  );
}

const SignatureField = () => {
  let signature;
  const form = useForm();

  return (
    <div className="w-full bg-primary flex justify-center p-10 signature-field-section">
      <div className="flex flex-col items-center">
        <Field name={`handtekeningBeleggersProfiel`} validate={required}>
          {({ meta }) => (
            <div>
              <h2 className="uppercase text-xl text-white font-semibold mb-6">
                Handtekening
                {meta.error && meta.touched && (
                  <span className="text-omegaRed">{meta.error}</span>
                )}
              </h2>
            </div>
          )}
        </Field>

        <div>
          <ReactSignatureCanvas
            canvasProps={{
              width: 500,
              height: 200,
              className: " rounded-lg bg-white",
            }}
            ref={(ref) => {
              signature = ref;
            }}
            onEnd={() => {
              form.mutators.setValue(
                "handtekeningBeleggersProfiel",
                signature.getTrimmedCanvas().toDataURL("image/png")
              );
            }}
          />
          <div
            className="text-sm cursor-pointer text-right text-white mt-2"
            onClick={() => signature.clear()}
          >
            wissen
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeleggersProfiel;
