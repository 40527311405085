import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import BeleggersProfiel from "./BeleggersProfiel";
import Confirmation from "./confirmation";
import { AuthContext } from "./context/AuthContext";
import DuurzaamheidVoorkeuren from "./DuurzaamheidVoorkeuren";
import Login from "./pages/login";
import PersonalInformation from "./PersonalInformation";

const AppRoutes = () => {
  const [userData, setUserData] = useState();
  const [formValues, setFormValues] = useState();
  const [jwt, setJwt] = useState();


  return (
    <AuthContext.Provider
      value={{
        user: userData,
        setUser: setUserData,
        formValues,
        setFormValues,
        jwt,
        setJwt,
      }}
    >
      <Router>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" index element={<Login />} />
            <Route
              path="/beleggersprofiel"
              element={
                <ProtectedRoute user={userData}>
                  <BeleggersProfiel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/duurzaamheidvoorkeuren"
              element={
                <ProtectedRoute user={userData}>
                  <DuurzaamheidVoorkeuren />
                </ProtectedRoute>
              }
            />
            <Route
              path="/confirmation"
              element={
                <ProtectedRoute user={userData}>
                  <Confirmation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/personal-information"
              element={
                <ProtectedRoute user={userData}>
                  <PersonalInformation />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AnimatePresence>
      </Router>
    </AuthContext.Provider>
  );
};

const ProtectedRoute = ({ user, redirectPath = "/", children }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <motion.div
      className=""
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default AppRoutes;
